import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.min.css'

const swiper = new Swiper('.sliderIntroSlides', {
  modules: [Pagination, Autoplay, EffectFade],

  // autoplay: {
  //   delay: 5000,
  // },
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
  },
  pagination: {
    el: '.sliderIntroSlidePagination',
    clickable: true,
  },
})
